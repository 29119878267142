import React, { useEffect, useState } from "react";
import "./login.css";
import BearerMessage from "./components/BearerMessage";
import { ScimResponse, GetBridgePath } from "../helpers/router";
import { useRedirect } from "../helpers/redirect";

const Login = (): JSX.Element => {
  const state = useRedirect();
  const [token, setToken] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [notifyMsg, setNotifyMsg] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const install = params.get("install");
    if (install) {
      setNotifyMsg(
        "'scimsession' file installed. Input your Bearer Token to continue."
      );
    }
  }, []);

  const verify = (event: React.SyntheticEvent) => {
    event.preventDefault();

    GetBridgePath("/login/session", token)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((json) => {
        window.sessionStorage.setItem("session-token", json.sessiontoken);
        console.log("redirecting");
        window.location.replace("/app/status");
        setTimeout(() => {
          window.location.href = "/app/status";
        }, 1000);
      })
      .catch((err) => {
        console.log("bearer token error");
        if (err.status === 403) {
          return err
            .json()
            .then((message: Response) => {
              return message;
            })
            .then((res: ScimResponse) => setErrorMsg(res.detail));
        } else {
          setErrorMsg("Incorrect bearer token.");
          return;
        }
      });
  };

  if (state.loading) {
    return <></>;
  }

  if (!state.sessionFileFound) {
    window.location.replace("/app/setup");
  }

  return (
    <div>
      <h2>1Password SCIM Bridge Login</h2>
      <div id="bearer-token-input" className="row">
        <section className="status"></section>
        <section className="description">
          <p id="bearer-token-description">Enter your OAuth bearer token:</p>
          <form onSubmit={verify} id="bearer-input-form">
          <label htmlFor="bearer-token-field"></label>
            <input
              type="password"
              value={token}
              onChange={(e) => setToken(e.currentTarget.value)}
              id="bearer-token-field"
              alt="Password"
            />
            <button type="submit" className="verify">
              Verify
            </button>
          </form>
        </section>
      </div>
      {(errorMsg || notifyMsg) && (
        <BearerMessage errorMsg={errorMsg} notifyMsg={notifyMsg} />
      )}
    </div>
  );
};

export default Login;
