import { useEffect } from "react";
import "../setup/setup.css";
import {
  GetBridgePath,
  PostBridgePath,
  GetAddressPrefix,
} from "../helpers/router";
import { useRedirect } from "../helpers/redirect";

const Install = (): JSX.Element => {
  const state = useRedirect();

  useEffect(() => {
    const domain = window.location.hostname;
    const addressPrefix = GetAddressPrefix(domain);
    const installAddress = addressPrefix + "/install";

    if (!domain) {
      console.error("unable to determine domain");
      window.location.href = "/app";
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const scimsession =
      params.get("scimsession") || window.location.hash.replace("#", "");

    if (scimsession.length === 0) {
      console.error("install failed, scimsession provided is empty");
      return;
    }

    try {
      PostBridgePath(installAddress, scimsession);
    } catch (err) {
      console.error("install call failed: " + err);
      return;
    }

    const pingAddress = addressPrefix + "/ping";

    const pingBridge = async () => {
      try {
        await GetBridgePath(pingAddress);
        console.log("ping succeeded, redirecting");
        window.location.replace("/app/login?install=1");
      } catch (err) {
        console.error("ping failed: " + err);
      }
    };

    setTimeout(pingBridge, 2000);
  }, []);

  if (state.loading) {
    return <></>;
  }

  if (state.sessionFileFound) {
    window.location.replace("/app/login");
  }

  return (
    <div>
      <h2>1Password SCIM Bridge Setup</h2>

      <h2>Installing...</h2>
      <div id="install-message" className="row">
        <p id="redirect-notice">
          You should be redirected shortly. If not, try clicking{" "}
          <a href="/app/login?install=1">this link.</a>
        </p>
      </div>
    </div>
  );
};

export default Install;
